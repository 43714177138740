import * as React from "react"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <div className="h-screen w-screen bg-gray-100 flex items-center">
      <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
        <div className="max-w-md">
          <div className="text-10xl font-dark font-bold">404</div>
          <p className="text-2xl md:text-3xl font-light leading-normal">Se trata de un error. </p>
          <p className="mb-8">La URL solicitada no se encontró en este servidor.</p>
          <p className="text-2xl md:text-3xl font-light leading-normal">Es todo lo que sabemos </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
